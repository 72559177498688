.lay-wrapper {
  margin: 0 auto;
  width: 100%;
  @include clearfix;
  position: relative;
  transition: transform 0.5s ease-in-out;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--header {
    top: 0;
    height: 70px;
    background: var(--ceres-white);
    border-bottom: 2px solid var(--ceres-primary-color-8);
    position: fixed;
    z-index: 12 !important;
  }

  &--footer {
    left: 0;
    right: 0;
    z-index: 60;
    bottom: 0;
    position: fixed;
    height: 60px;
    background-color: var(--ceres-footer-color);
    width: 100%;

    @media (max-width: 575.98px) {
      .nav {
        padding: 0 !important;
      }
    }

    .nav {
      padding: 20px;
    }

    .gms-badge {
      color: #000028;
      margin-left: 48px;
      min-width: 150px;
      padding: 0 84px 0 96px;
      display: flex;
      height: 100%;
      align-items: center;
      align-content: center;
      background: linear-gradient(15deg,#00ffb9 0%,#00e6dc 100%);
      clip-path: polygon(15% 0%,100% 0%,100% 100%,0% 100%);

      strong {
        font: caption;
        font-weight: 600;
      }

      &:hover {
        text-decoration-line: underline;
        text-underline-offset: 5px;
      }
    }
  }

  &--default {
    width: 92.5%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    flex: 1 0 auto;
    padding: 0px;
    min-height: calc(100vh - 160px);
  }

  &--full-width {
    width: 100%;
    position: relative;
    @include clearfix();
  }

  &--top-distance {
    padding-top: 125px;

    &-small {
      top: 90px;
      bottom: 90px
    }
  }

  &--bottom-distance {
    padding-bottom: 100px;

    &-small {
      padding-bottom: 65px;
    }
  }
}
