// text atoms

//TODO [STYLING] longterm remove
h1,
.a-text-headline-page {
  @include headline-page(var(--ceres-primary-color-3));
}

h2,
.a-text-headline-chapter {
  @include headline-chapter(var(--ceres-primary-color-3));
}

h3,
.a-text-headline-component {
  @include headline-component(var(--ceres-primary-color-3));
}

h4,
.a-text-headline-txt {
  @include headline-txt(var(--ceres-primary-color-3));
}

p,
.a-text-copy-txt {
  @include copy-txt(var(--ceres-primary-color-3));
}

.a-text-copy-txt-bold {
  @include copy-txt-bold(var(--ceres-primary-color-3));
}

.a-text-copy-txt-chapter-title {
  @include copy-txt-chapter-title(var(--ceres-secondary-color-16));
}

.a-text-copy-txt-subtitle {
  @include copy-txt-subtitle(var(--ceres-primary-color-3));
}

.a-text-copy-txt-info {
  @include copy-txt-info;
}

.a-text-link-content {
  @include link-content;
}

.a-text-link-copy-txt {
  @include link-copy-txt;
}

.a-text-link-nav-generic {
  @include link-nav-generic;
}

.a-text-link-nav-primary {
  @include link-nav-primary;
}

.a-text-link-arrow {
  @include link-arrow;
}
