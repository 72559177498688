// button atoms

.a-button {
  &--pwo {
    @include button(pwo);
  }
  &--cancel {
    @include button(cancel);
  }
  &--warning {
    @include button(warning);
  }
  &--danger {
    @include button(danger);
  }
}
