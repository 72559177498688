.menu-wrapper {
  position: fixed;
  top: 70px;
  bottom: 60px;
  left: 0;
  overflow: hidden;
  width: 60px;
  z-index: 1;
  background: transparent;
  transition: transform 0.3s, width 0.3s;

  .outer {
    height: 100%;
    width: calc(100vw - 380px);
    position: absolute;
    right: 0px;
    background: transparent;
  }

  &.open-all {
    transform: translateX(0px);
    width: 100%;
    z-index: 9;

    .scroller {
      transform: translateX(0px);
    }

    .submenu li.active {
      padding-left: 35px;
    }
  }

  .scroller {
    position: absolute;
    overflow-y: scroll;
    width: 380px;
    height: 100%;
    background: var(--ceres-sidenav-color);
    left: 0;
    transform: translateX(0px);

    .menu {
      text-align: left;
      font-size: 18px;
    }
  }

  li {
    background-color:  var(--ceres-sidenav-color);

    a {
      display: flex;
      flex-direction: row;
      height: 60px;
      padding: 20px 0px !important;
      position: relative;
      .a-icon {
        font-size: 24px;
        position: relative;
        top: -3px;
        &::before {
          width: 60px;
        }
        &.a-icon--attach_money {
          font-size: 34px;
          top: -8px;
        }
      }
      .arrow {
        position: absolute;
        font-size: 14px;
        right: 0px;
        top: 24px;
      }
      &:hover {
        background-color: var(--ceres-primary-color-1);
      }
    }
    .nav-icon-down {
      display: none;
    }

    .nav-icon-right {
      display: inherit;
    }

    &.open {
      .nav-icon-down {
        display: inherit;
      }

      .nav-icon-right {
        display: none;
      }

      .submenu {
        li {
          height: 60px;
        }
      }
    }

    &.active, &.open {
      background-color: var(--ceres-primary-color-1);
    }
  }

  .nav-icon {
    position: relative;

    i {
      position: absolute;
      right: 10px;
      top: 20px;
    }

    &::before {
      font-size: 20px;
      padding: 0px 20px;
    }
  }
}

.submenu {
  li {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
    padding-left: 35px;

    &:hover, &.active {
      a {
        color: var(--ceres-primary-color-1);
        background-color:  var(--ceres-sidenav-color);
      }
    }
  }

  li.active {
    background-color:  var(--ceres-sidenav-color);
    a {
      color: var(--ceres-primary-color-1);
    }
  }

  &.closed {
    li {
      height: 0px !important;
    }
    li.active {
      height: 0px;
    }
  }
}
