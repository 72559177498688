.mod-navigation {
  position: relative;

  /* ICONS */
  &--icons {
    width: auto;
    height: 60px;
    right: -11px;
    top: 0;
    @include flex-center();
    position: absolute;
    z-index: 1;
  }

  &--main {

    &-desktop {

      margin-left: 15%;
      width: 70%;
      display: none;

      @include mq(desktop-s) {
        display: block;
      }

      > ul {
        list-style: none;
        position: relative;
        width: 100%;
        height: 60px;
        @include flex-center();
        flex-direction: row;

        > li {
          position: relative;
          margin: 0 15px;

          @include mq(desktop-l) {
            transform: translateY(-4px);
          }

          &:hover {
            > a {
              color: var(--ceres-primary-color-3);
            }
          }
        }

        > .m-active-line {
          bottom: 2px;
          height: 4px;
          background: var(--ceres-secondary-color-6);
          position: absolute;
          transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
          display: none;

          @include mq(desktop-s) {
            display: block;
          }
        }
      }
    }
  }
}
