@mixin icon($type) {

  &:before {
    font-family: 'siemens-webicons-webfont', sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // siemens webfont icons
    @if $type==apps {
      content: '\e600';
    }

    @if $type==arrow-down-extrasmall {
      content: "\e601";
    }

    @if $type==arrow-left-extrasmall {
      content: "\e602";
    }

    @if $type==arrow-right-extrasmall {
      content: "\e603";
    }

    @if $type==arrow-up-extrasmall {
      content: "\e604";
    }

    @if $type==arrow-down-large {
      content: "\e605";
    }

    @if $type==arrow-left-large {
      content: "\e606";
    }

    @if $type==arrow-right-large {
      content: "\e607";
    }

    @if $type==arrow-up-large {
      content: "\e608";
    }

    @if $type==arrow-down-small {
      content: "\e609";
    }

    @if $type==arrow-left-small {
      content: "\e60a";
    }

    @if $type==arrow-right-small {
      content: "\e60b";
    }

    @if $type==arrow-up-small {
      content: "\e60c";
    }

    @if $type==arrow-to-top {
      content: "\e60d";
    }

    @if $type==at {
      content: "\e60e";
    }

    @if $type==bookmark {
      content: "\e60f";
    }

    @if $type==calculate {
      content: "\e610";
    }

    @if $type==calendar {
      content: "\e611";
    }

    @if $type==chat {
      content: "\e612";
    }

    @if $type==check-mark {
      content: "\e613";
    }

    @if $type==circle {
      content: "\e614";
    }

    @if $type==close {
      content: "\e615";
    }

    @if $type==control {
      content: "\e616";
    }

    @if $type==delete {
      content: "\e617";
    }

    @if $type==double-arrow-backward {
      content: "\e618";
    }

    @if $type==double-arrow-down {
      content: "\e619";
    }

    @if $type==double-arrow-forward {
      content: "\e61a";
    }

    @if $type==double-arrow-up {
      content: "\e61b";
    }

    @if $type==download {
      content: "\e61c";
    }

    @if $type==drag {
      content: "\e61d";
    }

    @if $type==edit {
      content: "\e61e";
    }

    @if $type==email {
      content: "\e61f";
    }

    @if $type==ext {
      content: "\e620";
    }

    @if $type==facebook {
      content: "\e621";
    }

    @if $type==facebook-negative {
      content: "\e622";
    }

    @if $type==fax {
      content: "\e623";
    }

    @if $type==feature {
      content: "\e933";
    }

    @if $type==file {
      content: "\e624";
    }

    @if $type==avi {
      content: "\e625";
    }

    @if $type==doc {
      content: "\e626";
    }

    @if $type==eps {
      content: "\e627";
    }

    @if $type==jpg {
      content: "\e628";
    }

    @if $type==mov {
      content: "\e629";
    }

    @if $type==mp3 {
      content: "\e62a";
    }

    @if $type==mp4 {
      content: "\e62b";
    }

    @if $type==png {
      content: "\e62d";
    }

    @if $type==ppt {
      content: "\e62e";
    }

    @if $type==files {
      content: "\e62f";
    }

    @if $type==tif {
      content: "\e630";
    }

    @if $type==vcf {
      content: "\e631";
    }

    @if $type==xls {
      content: "\e632";
    }

    @if $type==zip {
      content: "\e633";
    }

    @if $type==forum {
      content: "\e634";
    }

    @if $type==globe {
      content: "\e636";
    }

    @if $type==glossary {
      content: "\e637";
    }

    @if $type==google {
      content: "\e638";
    }

    @if $type==google-negative {
      content: "\e639";
    }

    @if $type==home {
      content: "\e63a";
    }

    @if $type==information {
      content: "\e63b";
    }

    @if $type==information-package {
      content: "\e63c";
    }

    @if $type==instagram {
      content: "\e63d";
    }

    @if $type==instagram-negative {
      content: "\e63e";
    }

    @if $type==legal-notice {
      content: "\e63f";
    }

    @if $type==level-up {
      content: "\e640";
    }

    @if $type==linkedin {
      content: "\e641";
    }

    @if $type==linkedin-negative {
      content: "\e642";
    }

    @if $type==list-view {
      content: "\e643";
    }

    @if $type==location-pin {
      content: "\e644";
    }

    @if $type==log-in {
      content: "\e645";
    }

    @if $type==log-out {
      content: "\e646";
    }

    @if $type==menu {
      content: "\e647";
    }

    @if $type==minus {
      content: "\e648";
    }

    @if $type==more {
      content: "\e649";
    }

    @if $type==navigation-gyroscope {
      content: "\e64a";
    }

    @if $type==investor-relations {
      content: "\e90a";
    }

    @if $type==navigation-mouse {
      content: "\e64b";
    }

    @if $type==newton_product-catalogue {
      content: "\e64c";
    }

    @if $type==outline {
      content: "\e64d";
    }

    @if $type==phone {
      content: "\e64e";
      transform: translate(0, 0.5px) !important;
    }

    @if $type==play {
      content: "\e64f";
    }

    @if $type==plus {
      content: "\e650";
    }

    @if $type==print {
      content: "\e651";
    }

    @if $type==rating {
      content: "\e652";
    }

    @if $type==region {
      content: "\e653";
    }

    @if $type==replay {
      content: "\e91b";
    }

    @if $type==rss {
      content: "\e655";
    }

    @if $type==scaling {
      content: "\e656";
    }

    @if $type==search {
      content: "\e657";
    }

    @if $type==service {
      content: "\e905";
    }

    @if $type==share-ios {
      content: "\e658";
    }

    @if $type==share-newton-android {
      content: "\e659";
    }

    @if $type==shopping-cart {
      content: "\e65a";
    }

    @if $type==clock {
      content: "\e907";
    }

    @if $type==settings {
      content: "\e90f";
    }

    @if $type==show-location {
      content: "\e65b";
    }

    @if $type==single-arrow-backward {
      content: "\e65c";
    }

    @if $type==single-arrow-down {
      content: "\e65d";
    }

    @if $type==single-arrow-forward {
      content: "\e65e";
    }

    @if $type==single-arrow-up {
      content: "\e65f";
    }

    @if $type==slider {
      content: "\e660";
    }

    @if $type==social-feed {
      content: "\e661";
    }

    @if $type==speakers-off {
      content: "\e662";
    }

    @if $type==speakers-on {
      content: "\e663";
    }

    @if $type==subscribe {
      content: "\e664";
    }

    @if $type==theenergyblog {
      content: "\e665";
    }

    @if $type==theenergyblog-negative {
      content: "\e666";
    }

    @if $type==tile-view {
      content: "\e667";
    }

    @if $type==twitter {
      content: "\e668";
    }

    @if $type==twitter-negative {
      content: "\e669";
    }

    @if $type==user-account {
      content: "\e66a";
    }

    @if $type==upload {
      content: "\f21f";
    }

    @if $type==video-chat {
      content: "\e66b";
    }

    @if $type==warning {
      content: "\e66c";
    }

    @if $type==zoom {
      content: "\e66f";
    }

    @if $type==support {
      content: "\e904";
    }

    @if $type==vector {
      content: "\eb900";
    }

    @if $type==bell-solid {
      content: "\eb901";
    }

    @if $type==nav-icon {
      content: "\eb902";
    }

    @if $type==back-projects-arrow {
      content: "\eb903";
    }

    @if $type==attach_money {
      content: "\eb904";
    }

    @if $type==info {
      content: "\eb905";
    }

    @if $type==euro-coin {
      content: "\eb908";
    }

    @if $type==toolbox {
      content: "\eb909";
    }
  }

  @if $type==tile-arrow-light {
    width: 18px;
    height: 31px;
    background: url('../../svgs/icon-tile-arrow-light.svg') no-repeat;
    background-size: 100%;
  }

  @if $type==tile-arrow-dark {
    width: 18px;
    height: 31px;
    background: url('../../svgs/icon-tile-arrow-dark.svg') no-repeat;
    background-size: 100%;
  }

  @if $type==checkbox-check {
    width: 15px;
    height: 12px;
    background: url('../../svgs/icon-checkbox-check.svg') no-repeat;
    background-size: 100%;
  }

  @if $type==checkbox-check-white {
    width: 15px;
    height: 12px;
    background: url('../../svgs/icon-checkbox-check-white.svg') no-repeat;
    background-size: 100%;
  }
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
  url(../../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../../fonts/MaterialIcons-Regular.woff) format("woff"),
  url(../../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}


