@font-face {
  font-family: "Siemens Sans";
  src: url('../../fonts/siemenssans-roman-webfont.woff2') format('woff2'), url('../../fonts/siemenssans-roman-webfont.woff') format('woff'), url("../../fonts/SiemensSans-Roman.otf");
}

@font-face {
  font-family: "Siemens Sans Bold";
  src: url('../../fonts/siemenssans-bold-webfont.woff2') format('woff2'), url('../../fonts/siemenssans-bold-webfont.woff') format('woff'), url("../../fonts/SiemensSans-Bold.otf");
}

@font-face {
  font-family: "Siemens Serif Bold";
  src: url('../../fonts/siemensserif-bold-webfont.woff2') format('woff2'), url('../../fonts/siemensserif-bold-webfont.woff') format('woff'), url("../../fonts/SiemensSerif-Bold.otf");
}

@font-face {
  font-family: "Siemens Sans Italic";
  src: url('../../fonts/siemenssans-italic-webfont.woff2') format('woff2'), url('../../fonts/siemenssans-italic-webfont.woff') format('woff'), url("../../fonts/SiemensSans-Italic.otf");
}

@font-face {
  font-family: 'siemens-webicons-webfont';
  src: url('../../fonts/siemens-webicons-webfont.eot?avcggw');
  src: url('../../fonts/siemens-webicons-webfont.eot?avcggw#iefix') format('embedded-opentype'),
  url('../../fonts/siemens-webicons-webfont.ttf?avcggw') format('truetype'),
  url('../../fonts/siemens-webicons-webfont.woff?avcggw') format('woff'),
  url('../../fonts/siemens-webicons-webfont.svg?avcggw#siemens-webicons-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SH Headline';
  // src: url('/assets/fonts/SH-Bree-Headline-Regular.woff2') format('woff2');
  src: url('../../fonts/SH-Bree-Headline-Regular.woff2') format('woff2');
}


/**
 *  ====================== Fontstack ======================
 */
@mixin fontstack($typo: siemens-sans, $size: 16px, $line: 1, $letter: 1px, $color: var(--ceres-primary-color-3), $weight: 400) {

  @if $typo == siemens-sans {
    font-family: "Siemens Sans", sans-serif;
  }

  @if $typo == siemens-sans-bold {
    font-family: "Siemens Sans Bold", sans-serif;
  }

  @if $typo == siemens-serif-bold {
    font-family: "Siemens Serif Bold", serif;
  }

  @if $typo == siemens-sans-italic {
    font-family: "Siemens Sans Italic", sans-serif;
  }

  @if $typo == sh-headline {
    font-family: "SH Headline", sans-serif;
  }


  font-size: $size;
  line-height: $line;
  letter-spacing: $letter;
  font-weight: $weight;
  color: $color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin headline-page($color) {
  @include fontstack(siemens-sans, 46px, 57px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 46px * $desktop-l-factor, 57px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-chapter($color) {
  @include fontstack(siemens-sans-bold, 30px, 36px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 30px * $desktop-l-factor, 36px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component($color) {
  @include fontstack(siemens-sans, 24px, 30px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 24px * $desktop-l-factor, 30px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component-bold($color) {
  @include fontstack(siemens-sans-bold, 24px, 30px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 24px * $desktop-l-factor, 30px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component-small($color) {
  @include fontstack(siemens-sans, 20px, 28px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 20px * $desktop-l-factor, 28px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-component-small-bold($color) {
  @include fontstack(siemens-sans-bold, 20px, 28px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 20px * $desktop-l-factor, 28px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin headline-txt($color) {
  @include fontstack(siemens-sans-bold, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-bold($color) {
  @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-chapter-title($color) {
  @include fontstack(siemens-sans, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-chapter-title-italic($color) {
  @include fontstack(siemens-sans-italic, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-italic, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-subtitle($color) {
  @include fontstack(siemens-sans-bold, 18px, 24px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-info {
  @include fontstack(siemens-sans, 14px, 20px, 0, var(--ceres-secondary-color-16), 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, var(--ceres-secondary-color-16), 400);
  }
}

@mixin copy-txt-small($color) {
  @include fontstack(siemens-sans, 12px, 18px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-small-bold($color) {
  @include fontstack(siemens-sans-bold, 12px, 18px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-x-small($color) {
  @include fontstack(siemens-sans, 11px, 14px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 11px * $desktop-l-factor, 14px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-extra-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin copy-txt-extra-small-bold($color) {
  @include fontstack(siemens-sans-bold, 10px, 13px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin footer-txt($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}


@mixin copy-txt-italic($color) {
  @include fontstack(siemens-sans-italic, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-italic, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin nav-txt-login($color) {
  @include fontstack(siemens-sans-bold, 14px, 16px, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin nav-txt-circle-txt($color) {
  @include fontstack(siemens-sans-bold, 11px, 1, 0, $color, 400);
}

@mixin tile-chart-table-head($color) {
  @include fontstack(siemens-sans-bold, 11px, 14px, 0.1px, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 11px * $desktop-l-factor, 1 * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin link-txt-small($color) {
  @include fontstack(siemens-sans, 12px, 20px, 0, $color, 400);
  text-decoration: underline;
  position: relative;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 12px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin link-content {
  @include fontstack(siemens-sans, 14px, 20px, 0, var(--ceres-secondary-color-6), 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, var(--ceres-secondary-color-6), 400);
  }

  &:hover,
  .active {
    color: var(--ceres-secondary-color-5);
    border-bottom: 1px solid var(--ceres-secondary-color-5);
  }
}

@mixin link-copy-txt {
  @include fontstack(siemens-sans, 14px, 20px, 0, var(--ceres-secondary-color-6), 400);
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid var(--ceres-secondary-color-6);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, var(--ceres-secondary-color-6), 400);
  }

  &:hover,
  .active {
    color: var(--ceres-secondary-color-5);
    border-bottom: 1px solid var(--ceres-secondary-color-5);
  }
}

@mixin link-nav-generic {
  @include fontstack(siemens-sans, 14px, 20px, 0, var(--ceres-secondary-color-16), 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, var(--ceres-secondary-color-16), 400);
  }

  &:hover,
  .active {
    color: var(--ceres-secondary-color-5);
    border-bottom: 1px solid var(--ceres-secondary-color-5);
  }
}

@mixin link-nav-primary {
  @include fontstack(siemens-sans, 14px, 20px, 0, var(--ceres-secondary-color-16), 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-s) {
    font-size: 18px;
    line-height: 24px;
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, var(--ceres-secondary-color-16), 400);
  }
}

@mixin profile-name($color) {
  @include fontstack(siemens-sans, 18px, 24px, 0, $color, 400);
  text-decoration: none;
  position: relative;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin link-arrow {
  @include fontstack(siemens-sans, 14px, 16px, 0, var(--ceres-secondary-color-6), 400);
  text-decoration: none;
  position: relative;
  display: block;

  @include mq(desktop-l) {
    font-size: 18px;
    line-height: 21px;
  }

  &.a-icon {
    &:before {
      font-size: 18px;
      color: var(--ceres-secondary-color-6);
      transform: translate(-6px, 3px);
      display: inline-block;

      @include mq(desktop-l) {
        transform: translate(-6px, 2px);
      }
    }
  }

  &:hover,
  .active {
    color: var(--ceres-secondary-color-5);

    &.a-icon {
      &:before {
        color: var(--ceres-secondary-color-5);
      }
    }
  }
}


@mixin link-arrow-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  text-decoration: underline;
  display: inline-block;
  @include icon(arrow-right-small);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
    transform: translate(-1px, 1px);
    display: inline-block;
  }
}

@mixin link-arrow-big($color) {
  @include fontstack(siemens-sans, 14px, 16px, 0, $color, 400);
  text-decoration: none;
  @include icon(arrow-right-small);
  text-indent: -20px;
  padding-left: 17px;
  display: inline-block;
  cursor: pointer;
  position: relative;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
    text-decoration: none;
    transform: translate(0, 1px);
    width: 17px;
    margin-right: 3px;
    display: inline-block;
    text-indent: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

@mixin link-download-small($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  display: inline-block;
  @include icon(download);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
    transform: translate(-1px, 1px);
    margin: 0 0 0 2px;
    display: inline-block;
    float: right;
  }

  &:hover {
    text-decoration: underline;
  }
}


@mixin link-arrow-ext-big($color) {
  @include fontstack(siemens-sans, 14px, 16px, 0, $color, 400);
  display: inline-block;
  @include icon(ext);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 14px * $desktop-l-factor, 16px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
    transform: translate(-1px, 2px);
    margin: 0 0 0 2px;
    display: inline-block;
    float: right;
  }

  &:hover {
    text-decoration: underline;
  }
}


@mixin link-arrow-ext($color) {
  @include fontstack(siemens-sans, 10px, 13px, 0, $color, 400);
  display: inline-block;
  @include icon(ext);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 10px * $desktop-l-factor, 13px * $desktop-l-factor, 0, $color, 400);
  }

  &:before {
    transform: translate(-1px, 2px);
    margin: 0 0 0 2px;
    display: inline-block;
    float: right;
  }

  &:hover {
    text-decoration: underline;
  }
}


@mixin quote($color) {
  @include fontstack(siemens-serif-bold, 120px, 36px, 0, $color, 400);
}


// Table
@mixin table-txt-small($color) {
  @include fontstack(siemens-sans, 10px, 16px, 0, $color, 400);
  opacity: 1;

  @include mq(desktop-s) {
    @include fontstack(siemens-sans, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin table-txt-small-bold($color) {
  @include fontstack(siemens-sans-bold, 10px, 16px, 0, $color, 400);
  opacity: 1;

  @include mq(desktop-s) {
    @include fontstack(siemens-sans-bold, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 12px * $desktop-l-factor, 18px * $desktop-l-factor, 0, $color, 400);
  }
}


@mixin table-txt-number-small($color) {
  @include fontstack(siemens-sans-bold, 10px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
    @include fontstack(siemens-sans-bold, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-m) {
    @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin table-txt-number-small-italic($color) {
  @include fontstack(siemens-sans-italic, 10px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
    @include fontstack(siemens-sans-italic, 12px, 18px, 0, $color, 400);
  }

  @include mq(desktop-m) {
    @include fontstack(siemens-sans-italic, 14px, 20px, 0, $color, 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-italic, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }
}


@mixin table-txt-number($color) {
  @include fontstack(siemens-sans-bold, 12px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
    @include fontstack(siemens-sans-bold, 18px, 24px, 0, $color, 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}

@mixin table-txt-number-italic($color) {
  @include fontstack(siemens-sans-italic, 12px, 16px, 0, $color, 400);

  @include mq(desktop-s) {
    @include fontstack(siemens-sans-italic, 18px, 24px, 0, $color, 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-italic, 18px * $desktop-l-factor, 24px * $desktop-l-factor, 0, $color, 400);
  }
}


@mixin table-txt-number-big {
  @include fontstack(siemens-sans-bold, 20px, 27px, 0, var(--ceres-primary-color-3), 400);

  @include mq(desktop-s) {
    @include fontstack(siemens-sans-bold, 24px, 30px, 0, var(--ceres-primary-color-3), 400);
  }

  @include mq(desktop-m) {
    @include fontstack(siemens-sans-bold, 30px, 36px, 0, var(--ceres-primary-color-3), 400);
  }

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 38px, 48px, 0, var(--ceres-primary-color-3), 400);
  }
}


// Chart
@mixin chart-txt-nav($color) {
  @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);
  position: relative;
  cursor: pointer;

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
  }

  @if $color == var(--ceres-white) {
    @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

    @include mq(desktop-l) {
      @include fontstack(siemens-sans, 14px * $desktop-l-factor, 20px * $desktop-l-factor, 0, $color, 400);
    }

    &:hover,
    &.active {
      color: var(--ceres-white);
      font-family: "Siemens Sans Bold", sans-serif;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: var(--ceres-white);
        position: absolute;
      }
    }

  } @else {
    &:hover,
    &.active {
      color: var(--ceres-secondary-color-7);

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: var(--ceres-secondary-color-7);
        position: absolute;
      }
    }
  }
}


@mixin chart($color) {
  @include fontstack(siemens-sans, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    font-size: 18px;
    line-height: 26px;
  }
}

@mixin chart-bold($color) {
  @include fontstack(siemens-sans-bold, 14px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    font-size: 18px;
    line-height: 26px;
  }
}

@mixin chart-small($color) {
  @include fontstack(siemens-sans, 10px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    font-size: 13px;
    line-height: 26px;
  }
}

@mixin chart-bold-small($color) {
  @include fontstack(siemens-sans-bold, 10px, 20px, 0, $color, 400);

  @include mq(desktop-l) {
    font-size: 13px;
    line-height: 26px;
  }
}

@mixin survey-small {
  @include fontstack(siemens-sans-bold, 12px, 20px, 0, var(--ceres-primary-color-3), 400);

  @include mq(desktop-l) {
    font-size: 15px;
    line-height: 23px;
  }
}

@mixin price-big-txt($color) {
  @include fontstack(siemens-sans, 28px, 34px, 0, $color, 400);
  overflow: hidden;
  text-overflow: ellipsis;

  @include mq(desktop-s) {
    font-size: 38px;
    line-height: 47px;
  }

  @include mq(desktop-m) {
    font-size: 46px;
    line-height: 57px;
  }

  @include mq(desktop-l) {
    font-size: 55px;
    line-height: 70px;
  }
}

@mixin number-circle-loader($color) {
  @include fontstack(siemens-sans-bold, 24px, 30px, 0, $color, 400);
}

@mixin step-txt($color) {
  @include fontstack(siemens-sans-bold, 14px, 0, 0, $color, 400);

  @include mq(desktop-l) {
    @include fontstack(siemens-sans-bold, 10px * $desktop-l-factor, 0px * $desktop-l-factor, 0, $color, 400);
  }
}
