@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin mq($type) {
  @if map-has-key($breakpoints, $type) {
    @media #{map-get($breakpoints, $type)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint #{$type}!";
  }
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    content: "";
    padding-top: ($height / $width) * 100%;
    display: block;
  }
}

@mixin grid-wrapper() {

  width: 92.5%;
  margin: 0 auto;
  max-width: $max-width-s;
  transition: max-width 0.25s ease-in-out;
  display: block;
  position: relative;

  @include mq(desktop-m) {
    max-width: $max-width-m;
  }

  @include mq(desktop-l) {
    max-width: $max-width-l;
  }

  @include mq(desktop-xl) {
    max-width: $max-width-xl;
  }
}


@mixin transition($type, $time) {

  @if $time == s {
    transition: $type 0.15s ease-in-out;
  }

  @if $time == m {
    transition: $type 0.5s ease-in-out;
  }

  @if $time == l {
    transition: $type 1s ease-in-out;
  }

  @if $time == xl {
    transition: $type 2s ease-in-out;
  }
}

@mixin list-item($type) {
  width: 100%;
  @include link-nav-primary;
  @include flex-center();
  @include transition(background, s);
  cursor: pointer;
  position: relative;
  padding: 0 20px;

  @if $type == new {
    background: var(--ceres-black);
  }

  a,
  label {
    @if $type == reg {
      @include copy-txt-chapter-title(var(--ceres-secondary-color-16));
    }
    @if $type == new {
      @include copy-txt-chapter-title(var(--ceres-white));
    }
  }

  > a {
    width: 100%;
    padding: 20px 50px 17px 0;
    display: block;
    @include transition(all, s);
  }

  input[type="checkbox"] + label {

    &:before {
      @if $type == reg {
        border: 1px solid var(--ceres-secondary-color-16) !important;
      }
      @if $type == new {
        border: 1px solid var(--ceres-white) !important;
      }
    }

    &:after {
      width: 15px;
      height: 12px;
      @if $type == reg {
        @include icon(checkbox-check);
      }
      @if $type == new {
        @include icon(checkbox-check-white);
      }
    }
  }

  &:before {
    content: '';
    left: 20px;
    bottom: -1px;
    width: calc(100% - 40px);
    height: 1px;
    background: var(--ceres-primary-color-7);
    position: absolute;
    pointer-events: none;
  }

  &:after {
    content: '';
    top: 50%;
    right: 19px;
    width: 9px;
    height: 15px;

    @if $type == reg {
      @include icon(tile-arrow-dark);
      opacity: 0.2;
    }
    @if $type == new {
      @include icon(tile-arrow-light);
      opacity: 1;
    }

    background-size: 9px 15px;
    transform: translate(0, -8px);
    position: absolute;
    @include transition(opacity, s);
    pointer-events: none;
  }

  &:hover {

    background: var(--ceres-secondary-color-5);

    &:before {
      content: none;
    }

    &:after {
      opacity: 1;
      @include icon(tile-arrow-light);
      background-size: 9px 15px;
    }

    a,
    label {
      @include copy-txt-chapter-title(var(--ceres-white));
    }

    input[type="checkbox"] + label {

      &:before {
        border: 1px solid var(--ceres-white) !important;
      }

      &:after {
        width: 15px;
        height: 12px;
        @include icon(checkbox-check-white);
      }
    }
  }
}



@mixin profile() {
  @include flex-center();
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  padding: 20px 0 17px 20px;
  position: relative;

  &:before {
    content: '';
    left: 20px;
    bottom: -1px;
    width: calc(100% - 40px);
    height: 1px;
    background: var(--ceres-primary-color-7);
    position: absolute;
  }

  > .m-image {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 1px solid var(--ceres-secondary-color-17);
    margin: 0 auto 16px auto;
    opacity: 0.7;
  }

  .m-info {
    @include copy-txt(var(--ceres-primary-color-3));
    margin: 0 auto 3px auto;
    display: block;
  }
}

@mixin notification() {

  right: 0;
  top: 0;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--ceres-white);
  background: var(--ceres-feedback-orange);
  position: absolute;
  @include nav-txt-circle-txt(var(--ceres-white));
  @include flex-center();
}
