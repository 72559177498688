.mod-navigation {

  &--title{
    cursor: pointer;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $header-height;
  }

  /* ICONS */
  &--icons {
    width: auto;
    height: $header-height;
    right: 0;
    @include flex-center();
    z-index: 1;

    &.left{
      right:180px;
    }
  }

  &--settings,
  &--document-repository,
  &--faq,
  &--main {
    $faq-headline-height: $header-height;
    $button-wrapper-height: 86px;

    &:before {
      content: '';
      right: -100%;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--ceres-black-opaque-0);
      position: fixed;
      transition: background 0.5s ease-in-out, right 0s ease-in-out;
      transition-delay: 0s, 0.5s;
    }

    > .m-content {
      top: 0;
      right: -$menu-width;
      width: $menu-width;
      height: 100%;
      background: var(--ceres-white);
      position: fixed;
      overflow: hidden;
      z-index: 9999;
      @include transition(right, m);

      @include mq(desktop-l) {
        right: -$menu-width-big;
        width: $menu-width-big;
      }

      > .m-navigation {
        padding: 20px;
        width: $menu-width;
        height: $header-height;
        right: 0;
        top: 0;
        background: var(--ceres-white);
        border-bottom: 1px solid var(--ceres-secondary-color-17);
        display: flex;
        align-items: center;
        position: absolute;
        @include transition(right, m);

        @include mq(desktop-l) {
          width: $menu-width-big;
          padding-top: 16px;
        }

        > .mod-close-page {
          right: 20px;
          top: 50%;
          transform: translate(0, -12px);
          position: absolute;
          cursor: pointer;
          z-index: 2;
        }

        > .m-back-button {
          left: 15px;
          top: 50%;
          width: 9px;
          height: 15px;
          @include icon(tile-arrow-dark);
          background-size: 9px 15px;
          transform: translate(0, -24px) rotate(180deg);
          position: absolute;
          cursor: pointer;
          display: none;
          opacity: 0.8;
          transition: all 0.3s ease-in-out;

          &:hover {
            opacity: 1;
            transform: translate(0, -24px) rotate(180deg) scale(1.05);
          }
        }

        > .m-headline {
          padding: 0 30px 0 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include headline-txt(var(--ceres-primary-color-3));

          &.center {
            text-align: center;
            padding-left: 30px;
          }
        }
      }

      > .m-bottom-wrapper {
        width: $menu-width;
        right: -$menu-width;
        bottom: 0;
        background: var(--ceres-white);
        position: fixed;
        height: auto;
        @include transition(right, m);

        @include mq(desktop-l) {
          width: $menu-width-big;
          right: -$menu-width-big;
        }

        > .m-bottom {

          &--profile {
            border-top: 1px solid var(--ceres-secondary-color-17);
            padding: 16px 20px 20px 20px;

            .m-user {
              @include profile();
            }
          }
        }

        &.hide {
          display: none;
        }
      }
    }

    &--prelogin {
      > .m-content {
        > .m-layer {
          padding-top: 35px;
        }
      }
    }

    &.show {

      &:before {
        background: var(--ceres-black-opaque-6);
        right: 0;
        transition: background 0.5s ease-in-out, right 0s ease-in-out;
        transition-delay: 0s, 0s;
      }

      > .m-content {
        right: 0;

        > .m-bottom-wrapper {
          right: 0;
        }
      }
    }
  }

  /* SETTINGS */

  &--settings,
  &--document-repository,
  &--main {

    > .m-content {

      > .m-layer {
        @include headline-txt(var(--ceres-primary-color-3));
        overflow-y: auto;
        width: $menu-width;
        right: 0;
        top: $header-height;
        background: var(--ceres-white);
        position: absolute;
        @include transition(right, m);
        overflow-x: hidden;

        @include mq(desktop-l) {
          width: $menu-width-big;
        }

        > .m-form {

          padding: 20px;

          form {

            .m-select-wrapper {
              margin: 0 0 28px 0;
              width: 100%;
              position: relative;

              label {
                @include profile-name(var(--ceres-primary-color-3));
                color: var(--ceres-secondary-color-16);
                display: block;
                margin: 0 0 15px 4px;
              }

              select::-ms-expand {
                display: none;
              }

              select {
                width: 100%;
                height: 42px;
                background: transparent;
                outline: none;
                border: 1px solid var(--ceres-primary-color-7);
                display: block;
                -webkit-appearance: none;
                -webkit-border-radius: 0;
                padding: 2px 12px 0 12px;
                @include copy-txt(var(--ceres-primary-color-3));
                position: relative;

                &:hover {
                  background: var(--ceres-black-opaque-1);

                  &:after {
                    opacity: 1;
                  }
                }

                &:focus {
                  -webkit-tap-highlight-color: transparent;
                }
              }
            }
          }
        }

        > ul {
          counter-reset: counter-first-layer;
          transform: translate(0, 0);
          @include transition(transform, m);
          width: $menu-width;

          @include mq(desktop-l) {
            width: $menu-width-big;
          }

          > li {
            @include list-item(reg);
            width: $menu-width;

            @include mq(desktop-l) {
              width: $menu-width-big;
            }

            > a {
              padding-left: 32px;

              @include mq(desktop-l) {
                padding-left: 44px;
              }

              &:before {
                margin: 0 0 0 -32px;
                content: counter(counter-first-layer) '.';
                counter-increment: counter-first-layer;
                position: absolute;

                @include mq(desktop-l) {
                  margin-left: -44px;
                }
              }

              > .m-desc {
                width: auto;
                position: relative;

                > .m-notification {
                  @include notification();
                  transform: translate(22px, -7px);
                }
              }
            }
          }
        }
      }

      &.show-second-layer {

        right: $menu-width;
        @include mq(desktop-l) {
          right: $menu-width-big;

        }
      }

    }
  }

  &--faq {

    > .m-content {

      > .m-layer {

        right: 0;
        height: 100%;
        position: relative;
        @include transition(all, m);

        > .m-topic-layer {
          @include headline-txt(var(--ceres-primary-color-3));
          overflow-y: auto;
          width: $menu-width;
          right: 0;
          top: $header-height;
          background: var(--ceres-white);
          position: absolute;
          z-index: 2;

          @include mq(desktop-l) {
            width: $menu-width-big;
          }

          > ul {
            counter-reset: counter-first-layer;
            transform: translate(0, 0);
            @include transition(transform, m);
            width: $menu-width;

            @include mq(desktop-l) {
              width: $menu-width-big;
            }

            > li {
              @include list-item(reg);
              width: $menu-width;

              @include mq(desktop-l) {
                width: $menu-width-big;
              }

              > a {
                padding-left: 32px;

                @include mq(desktop-l) {
                  padding-left: 44px;
                }

                &:before {
                  margin: 0 0 0 -32px;
                  content: counter(counter-first-layer) '.';
                  counter-increment: counter-first-layer;
                  position: absolute;

                  @include mq(desktop-l) {
                    margin-left: -44px;
                  }
                }
              }
            }
          }
        }

        > .m-question-layer {

          > .m-items {

            > .m-content {
              @include headline-txt(var(--ceres-primary-color-3));
              overflow-y: auto;
              width: $menu-width;
              top: $header-height;
              right: -$menu-width;
              background: var(--ceres-white);
              position: absolute;
              z-index: 1;

              @include mq(desktop-l) {
                width: $menu-width-big;
                right: -$menu-width-big;
              }

              > ul {
                position: relative;

                > li {
                  @include list-item(reg);
                }
              }
            }
          }
        }

        > .m-answer-layer {

          > .m-items {

            > .m-items {

              > .m-content {
                @include headline-txt(var(--ceres-primary-color-3));
                overflow-y: auto;
                width: $menu-width;
                top: $header-height;
                right: -$menu-width*2;
                padding: 20px 20px 40px 20px;
                background: var(--ceres-white);
                position: absolute;

                @include mq(desktop-l) {
                  width: $menu-width-big;
                  right: -$menu-width-big*2;
                }

                > h4 {
                  @include copy-txt-chapter-title(var(--ceres-secondary-color-16));
                  color: var(--ceres-primary-color-3);
                  margin: 0 0 10px 0;
                }

                > p {
                  @include copy-txt-info();
                }
              }
            }
          }
        }

        &.show-second-layer {
          right: $menu-width;

          @include mq(desktop-l) {
            right: $menu-width-big;
          }
        }

        &.show-answer-layer {
          right: $menu-width*2;

          @include mq(desktop-l) {
            right: $menu-width-big*2;
          }
        }
      }

      > .m-bottom-wrapper {

        > .m-bottom {

          &--faq {
            padding: 20px;
            border-top: 1px solid var(--ceres-secondary-color-17);

            .m-equate {
              @include flex-center();
              justify-content: flex-start;
            }

            .m-response {
              display: none;

              > span {
                @include copy-txt-bold(var(--ceres-primary-color-3));
                color: var(--ceres-primary-color-3);
                display: block;
                margin: 0 0 12px 0;
              }

              > .m-button-wrapper {
                @include flex-center();
                flex-direction: row;

                button {
                  width: 170px;
                  @include flex-center();

                  &:first-of-type {
                    margin-right: 10px;
                  }

                  &:last-of-type {
                    margin-left: 10px;
                  }
                }
              }
            }

            .m-form {
              display: none;

              > span {
                @include copy-txt-bold(var(--ceres-primary-color-3));
                color: var(--ceres-primary-color-3);
                display: block;
                margin: 0 0 14px 0;

                span {
                  @include copy-txt-bold(var(--ceres-primary-color-3));
                  color: var(--ceres-primary-color-3);
                }
              }

              > form {
                > .m-radio-wrapper {
                  margin: 0 0 30px 0;
                  @include clearfix();

                  > div {
                    float: left;
                    margin: 0 20px;

                    &:first-of-type {
                      margin-left: 0;
                    }
                  }
                }

                > .m-text-wrapper {
                  margin: 0 0 30px 0;
                  @include clearfix();

                  textarea {
                    width: 100%;
                    min-height: 145px;
                    resize: none;
                  }
                }

                > .m-button-wrapper {
                  @include flex-center();
                  flex-direction: row;

                  button,
                  input,
                  a {
                    width: 170px;
                    @include flex-center();

                    @include mq(desktop-l) {
                      width: 270px;
                    }

                    &:first-child {
                      margin-right: 10px;
                    }

                    &:last-child {
                      text-align: center;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            .m-thanks {
              display: none;

              > span {
                @include copy-txt-bold(var(--ceres-primary-color-3));
                color: var(--ceres-primary-color-3);
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &--main {
    height: 100%;

    &.hide {
      display: none;
    }

    &-mobile {

      > .m-content {

        > .m-layer {

          > .m-nav {

            > ul {

              list-style: none;
              position: relative;
              width: 100%;

              @include mq(desktop-s) {
                flex-direction: row;
              }

              > li {
                position: relative;
                @include list-item(single);

                > span {
                  position: relative;

                  > .m-notification {
                    @include notification();
                    transform: translate(14px, 7px);
                  }
                }

                @include mq(desktop-l) {
                  transform: translateY(-4px);
                }
              }
            }
          }
        }
      }
    }

    &-desktop {

      //margin-left: 15%;
      width: auto;
      display: none;

      @include mq(desktop-s) {
        display: flex;
        width: 60%;
        justify-content: center;
      }

      > ul {
        list-style: none;
        position: relative;
        width: 100%;
        height: 100%;
        @include flex-center();
        flex-direction: row;

        > li {
          position: relative;
          margin: 0 15px;
          height: 100%;
          display: flex;
          align-items: center;

          @include mq(desktop-l) {
            //transform: translateY(-4px);
          }

          > span {
            position: relative;

            > a {
              //padding: 22px 0 0 0;
              @include link-nav-primary;
              //height: 60px;
              display: block;
              transition: color 0.15s ease-in-out;
            }

            > .m-notification {
              @include notification();
              transform: translate(14px, -16px);
            }
          }

          &:hover {
            > a {
              color: var(--ceres-primary-color-3);
            }
          }
        }

        /*> .m-active-line {
          bottom: 0;
          height: 4px;
          background: var(--ceres-secondary-color-6);
          position: absolute;
          transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
          display: none;

          @include mq(desktop-s) {
            display: block;
          }
        }*/
      }
    }
  }
}
